<!-- 拍卖列表 -->
<template>
  <div class="actionBox">
    <el-form
      :model="queryParams"
      ref="queryRef"
      :inline="true"
      label-width="130px"
      size="medium"
    >
      <div class="formContainer">
        <div class="pubFormStyle">
          <el-form-item label-width="70px" label="拍卖编号" prop="auctionCode">
            <el-input
              v-model.trim="queryParams.auctionCode"
              placeholder="请输入拍卖编号"
              clearable
              @keyup.enter="handleQuery"
            />
          </el-form-item>
        </div>
        <div class="pubFormStyle">
          <el-form-item label="拍卖状态" prop="auctionStatus">
            <el-select
              v-model="queryParams.auctionStatus"
              placeholder="请选择拍卖状态"
              clearable
            >
              <el-option
                v-for="dict in dict.bid_status"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </div>

        <div class="pubFormStyle">
          <el-form-item label="开拍日期" prop="dateDay">
            <el-date-picker
              v-model="queryParams.dateDay"
              type="date"
              placeholder="请选择开拍日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>

        <div class="pubFormStyle">
          <el-form-item label-width="70px" label="标的地址" prop="address">
            <el-cascader v-model="queryParams.address" :options="areaList"  style="width:100%;"/>
            <!-- :options="getters.areaList" -->
          </el-form-item>
        </div>

        <div class="pubFormStyle">
          <el-form-item label="排序方式" prop="orderBy">
            <el-select
              v-model="queryParams.orderBy"
              placeholder="请选择排序方式"
              clearable
            >
              <el-option label="按开始时间正序" value="ASC" />
              <el-option label="按开始时间倒序" value="DESC" />
            </el-select>
          </el-form-item>
        </div>
        <div class="pubFormStyle searchStyle">
          <el-button size="small" type="success" plain @click="handleQuery"
            >筛选</el-button
          >
          <el-button size="small" type="success" plain @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
    </el-form>

    <div class="listBox" v-loading="loading" v-if="listData.length != 0">
      <div class="list" v-for="(item, index) in listData" :key="index">
        <div class="img">
          <img :src="item.url" alt="" />
        </div>

        <div class="listContent">
          <div class="title">
            <div
              :class="{
                status1: item.auctionStatus == '0',
                status2:
                  item.auctionStatus == '1' || item.auctioneerStatus == '1',
                status3:
                  item.auctionStatus != '0' &&
                  item.auctionStatus != '1' &&
                  item.auctioneerStatus != '1',
              }"
              class="public"
            >
              {{
                item.auctionStatus == "0"
                  ? "即将开始"
                  : item.auctionStatus == "1"
                  ? "正在拍卖"
                  : item.auctionStatus == "2" &&
                    (item.auctioneerStatus == "0" ||
                      item.auctioneerStatus == null)
                  ? "已成交"
                  : item.auctionStatus == "3"
                  ? "已流拍"
                  : item.auctionStatus == "4"
                  ? "已取消"
                  : item.auctionStatus == "5"
                  ? "已中止"
                  : item.auctioneerStatus == "1"
                  ? "待签署"
                  : item.auctioneerStatus == "2"
                  ? "签署成功"
                  : item.auctioneerStatus == "3"
                  ? "签署失败"
                  : "---"
              }}
            </div>
            <div class="wz">
              {{ item.auctionTitle }}
            </div>
          </div>
          <div class="contentDetail">
            <div class="timeLine">
              <el-timeline>
                <el-timeline-item
                  v-for="(activity, index) in item.timeLineData"
                  :key="index"
                  :timestamp="activity.timestamp"
                  placement="top"
                  :color="activity.color"
                  :icon="activity.icon"
                >
                  {{ activity.content }}
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="auctionDetail">
              <div class="actionCode">
                <span>拍卖编号</span>
                <span>{{ item.auctionCode }}</span>
              </div>
              <div class="deposit">
                <span>已缴纳保证金</span>
                <span>￥{{ formatMoneyString(item.bidBond) }} </span>
              </div>
              <div class="projectConsultation">
                <span>策拍人</span>
                <span>{{ item.person }}</span>
              </div>
              <div class="projectConsultation">
                <span>申请编码</span>
                <span>{{ item.applicationNumber }}</span>
              </div>
            </div>
          </div>

          <div class="btnBox">
            <!-- <div class="hall pub">拍卖直播</div> -->
            <el-button
              size="small"
              type="success"
              plain
              @click="toNoticeDetail(item.auctionCode)"
            >
              公告详情
            </el-button>
            <el-button
              size="small"
              type="success"
              plain
              @click="toBidDetail(item.auctionCode)"
            >
              拍卖详情
            </el-button>
            <!-- <div
              class="detail pub"
              v-if="item.auctionStatus == '0' || item.auctionStatus == '1'"
              @click="toChangeManagement()"
            >
              变更管理
            </div> -->
            <el-button
              size="small"
              type="success"
              @click="toPay(item.bidServiceFee, item.auctionCode,item.payType)"
              v-if="
                item.auctionStatus == '2' &&
                  (item.auctioneerStatus == null ||
                    item.auctioneerStatus == '0')
              "
            >
              费用待支付
            </el-button>
            <!-- <div class="detail pub">拍卖数据</div> -->
            <el-button
              size="small"
              @click="viewContract(item.signFlowId)"
              type="success"
              plain
              v-if="item.auctioneerStatus == '2'"
            >
              查看合同
            </el-button>
            <el-button
              size="small"
              @click="downloadContract(item.signFlowId)"
              type="success"
              plain
              v-if="item.auctioneerStatus == '2'"
            >
              下载合同
            </el-button>
            <!-- <el-button
              size="small"
              type="success"
              plain
              v-if="item.auctionStatus == '4'"
              @click="handleCancel('cancel')"
            >
              取消原因
            </el-button> -->
            <!-- <el-button
              size="small"
              type="success"
              plain
              v-if="item.auctionStatus == '5'"
              @click="handleCancel('discontinue')"
            >
              中止原因
            </el-button> -->
          </div>
        </div>
      </div>

      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="queryParams.pageNum"
          :page-size.sync="queryParams.pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div v-else class="noData">暂无数据</div>

    <!-- 取消原因/中止原因 -->

    <el-dialog :title="title" :visible.sync="cancelVisible" width="30%" center>
      <span>{{ reasonContent }}</span>
    </el-dialog>

    <!-- 支付弹窗 -->
    <el-dialog
      title="企业钱包"
      :visible.sync="bmPayVisible"
      width="480px"
      :close-on-click-modal="false"
    >
      <div class="payPriceBox">
        <div class="priceBox">
          <div class="title">支付金额</div>
          <div class="price">
            <span v-if="auctionData.payType==1">￥{{
              formatMoneyString(
                (auctionData.bidServiceFee).toFixed(2)
              )
            }}</span>
            <span v-else>￥{{
              formatMoneyString(
                (auctionData.bidServiceFee / 100).toFixed(2)
              )
            }}</span>
            
          </div>
        </div>

        <div class="amountAccounting">
          <div class="title">金额核算</div>

          <div class="deposit">
            <div>竞拍综合服务费</div>
            <div v-if="auctionData.payType==1">
              ￥{{
                formatMoneyString(
                  (auctionData.bidServiceFee).toFixed(2)
                )
              }}
            </div>
            <div v-else>
              ￥{{
                formatMoneyString(
                  (auctionData.bidServiceFee / 100).toFixed(2)
                )
              }}
            </div>
          </div>

          <div class="realPay">
            <div>实际支付</div>
            <div v-if="auctionData.payType==1">
              ￥{{
                formatMoneyString(
                  (auctionData.bidServiceFee).toFixed(2)
                )
              }}
            </div>
            <div v-else>
              ￥{{
                formatMoneyString(
                  (auctionData.bidServiceFee / 100).toFixed(2)
                )
              }}
            </div>
          </div>
        </div>

        <div class="payPassword">
          <div class="payAccount">
            <div>支付密码</div>
            <div>支付账户：{{ userInfo.manuName }}-企业钱包</div>
          </div>
          <div class="balance">
            可用余额：￥{{ formatMoneyString(withdrawalForm.availableBalance) }}
          </div>
        </div>

        <div class="password">
          <PasswordInput
            ref="passwordInputRef"
            v-model="withdrawalForm.paymentPassword"
            has-gap
            @complete="onPasswordInputComplete"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAuctionInventoryList,
  auctioneerPay,
  viewContract,
} from "@/api/workSpace";
import { useDict } from "@/utils/dict";
import { balance } from "@/api/account";
import { getInfo } from "@/api/index";
import PasswordInput from "@/pages/AccountSettings/components/PasswordInput.vue";
import moment from "moment";
import { mapState } from "vuex";
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
export default {
  components: {
    PasswordInput,
  },
  data() {
    return {
      bmPayVisible: false,
      title: "", //取消/中止原因title
      reasonContent: "", //取消/中止原因
      cancelVisible: false,
      loading: false,
      listData: [],
      dict: {},
      queryParams: {
        queryType: "client",
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      status: 0,
      baseUrl: "/api",
      withdrawalForm: {},
      auctionData: {},
      // areaList: [],
    };
  },
  watch: {
    bmPayVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs["passwordInputRef"].focus();
        });
      }
    },
  },
  computed: {
    ...mapState({
      areaList: (state) => state.area.areaList,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.dict = useDict("bid_status");
    // this.areaList = this.$store.state.area.areaList;
    // this.getList();
  },
  methods: {
    toPay(bidServiceFee, auctionCode,payType) {
      getInfo().then((res) => {
        if (res.code == 200) {
          if (res.user.walletStatus == "2") {
            this.$message({
              type: "warning",
              message: "您的账户已冻结，请联系管理员操作！",
            });
          } else {
            this.auctionData.bidServiceFee = bidServiceFee;
            this.auctionData.auctionCode = auctionCode;
            this.auctionData.payType = payType;
            this.bmPayVisible = true;
            balance().then(({ code, data }) => {
              if (code == 200) this.withdrawalForm = data;
            });
          }
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 查看合同、合同管理
    viewContract(data) {
      this.$router.push({
        path: "/contract",
        query: {
          signFlowId: data,
        },
      });
    },
    // 下载合同
    downloadContract(data) {
      viewContract({ signFlowId: data }).then((res) => {
        this.downloadFile(res.data.downloadUrl);
      });
    },
    getFileNameFromUrl(url) {
      const matches = url.match(/\/([^\/?#]+)[^\/]*$/);
      return matches ? decodeURIComponent(matches[1]) : null;
    },

    // 下载文件
    downloadFile(fileURL) {
      // 获取文件名
      const fileName = this.getFileNameFromUrl(fileURL);
      // console.log(fileName);
      // 使用fetch下载文件
      fetch(fileURL)
        .then((response) => response.blob())
        .then((blob) => {
          // 创建一个临时链接并触发下载
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName || "downloaded_file"; // 使用从URL解析得到的文件名，如果没有则使用默认文件名
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("文件下载失败：", error);
        });
    },

    // 密码输入完成回调
    onPasswordInputComplete(val) {
      let pwd = encrypt(val);
      let amount = 0;
      if(this.auctionData.payType==1){
        amount =(Number(this.auctionData.bidServiceFee)).toFixed(2)
      }else{
        amount =(Number(this.auctionData.bidServiceFee / 100)).toFixed(2)
      }
      let param = {
        authCode: this.auctionData.auctionCode,
        amount,
        pwd,
      };
      auctioneerPay(param).then((res) => {
        if (res.code == 200) {
          this.bmPayVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: res.msg,
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },

    // 拍卖详情
    toBidDetail(data) {
      this.$router.push({
        path: "/biddingProject/details",
        query: {
          auctionCode: data,
        },
      });
    },

    // 公告详情
    toNoticeDetail(data) {
      this.$router.push({
        path: "/Notice/announcementDetails",
        query: {
          auctionCode: data,
        },
      });
    },

    // 变更管理
    toChangeManagement() {
      this.$router.push({
        path: "/changeManagement",
        // query: {
        //   auctionCode: data,
        // },
      });
    },
    // 查询
    handleQuery() {
      this.getList();
    },
    // 重置
    resetQuery() {
      this.$refs["queryRef"].resetFields();
      this.getList();
    },
    // 分页
    handleCurrentChange() {
      this.getList();
    },

    // 取消原因
    handleCancel(data) {
      if (data == "cancel") {
        this.title = "取消原因";
        this.reasonContent = "取消原因1111";
      } else {
        this.title = "中止原因";
        this.reasonContent = "中止原因2222";
      }
      this.cancelVisible = true;
    },

    getList() {
      this.loading = true;
      let { address, ...args } = this.queryParams;
      if (this.queryParams.address) {
        let country = JSON.parse(JSON.stringify(this.queryParams.address));
        country = country.join("-");
        args.country = country;
      }
      getAuctionInventoryList(args).then((res) => {
        if (res.code == 200) {
          let data = res.rows.map((item) => {
            item.url =
              this.baseUrl +
              "/file/displayResource?objectName=" +
              item.objectNames[0];
            item.person = item.operator + " " + item.operatorNumber;
            item.timeLineData = [];
            item.timeLineData.push({
              content: "拍卖信息发布",
              timestamp: moment(new Date(item.releaseTime)).format(
                "YYYY-MM-DD HH:mm"
              ),
            });
            if (item.isPreview == "Y") {
              item.timeLineData.push({
              content: "预展览开始",
              timestamp: moment(new Date(item.previewStartTime)).format(
                "YYYY-MM-DD HH:mm"
              ),
            });
            item.timeLineData.push({
              content: "预展览结束",
              timestamp: moment(new Date(item.previewEndTime)).format(
                "YYYY-MM-DD HH:mm"
              ),
            });
            }

            item.timeLineData.push({
              content: "拍卖开始",
              timestamp: moment(
                new Date(item.dateDay + " " + item.auctionStartTime)
              ).format("YYYY-MM-DD HH:mm"),
            });
            item.timeLineData.push({
              content: "拍卖合同签署截止日期",
              timestamp: moment(new Date(item.contractLatestTime)).format(
                "YYYY-MM-DD HH:mm"
              ),
            });
            return item;
          });
          let nowDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
          // 给时间线添加color
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].timeLineData.length; j++) {
              if (data[i].timeLineData[j].timestamp < nowDate) {
                // 如果当前下标的日期小于当前时间，添加color显示为绿色
                data[i].timeLineData[j].color = "#0DAC65";
              } else {
                // 如果当前下标日期大于当前日期把当前日期push到当前下标前
                data[i].timeLineData.splice(j, 0, {
                  content: "当前日期",
                  timestamp: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                  icon: "el-icon-pie-chart",
                  color: "#0DAC65",
                });
                break;
              }
            }
          }
          this.listData = data;
          this.total = res.total;
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--medium .el-input__inner{
  height: 32px;
  line-height: 32px;
}
/deep/.el-form-item__label{
  color: rgba(0, 0, 0, 0.85);
}
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
/deep/.el-timeline-item {
  padding-bottom: 5px;
}
/deep/.el-timeline-item__wrapper {
  display: flex;
}

/deep/.el-timeline-item__timestamp.is-top {
  margin-right: 10px;
}
/deep/.el-timeline-item__timestamp{
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
/deep/.el-timeline-item__content{
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.actionBox {
  width: 100%;
  padding-top: 25px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .listBox {
    width: 100%;
    box-sizing: border-box;

    .list {
      width: 100%;
      height: 210px;
      padding: 20px;
      // background-color: #ccc;
      box-sizing: border-box;
      display: flex;
      box-shadow: 0 1px 0 0 #e0e0e0;
      .img {
        width: 305px;
        height: 170px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .listContent {
        width: calc(100% - 305px);
        padding-left: 28px;
        box-sizing: border-box;
        position: relative;
        .title {
          width: 100%;
          display: flex;
          .public {
            width: 84px;
            height: 24px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
          }
          // 即将开始
          .status1 {
            background: rgba(13, 172, 101, 0.1);
            color: #0dac65;
          }
          // 正在拍卖
          .status2 {
            background: rgba(255, 127, 0, 0.1);
            color: #ff7f00;
          }
          // 已结束
          .status3 {
            background: #f0f0f0;
            color: #00000099;
          }
          .wz {
            width: calc(100% - 84px);
            font-weight: 500;
            font-size: 18px;
            color: #000000d9;
            overflow: hidden;
            white-space: nowrap; /* 防止换行 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .contentDetail {
          width: 100%;
          height: 96px;
          margin-top: 18.5px;
          // background-color: #ccc;
          display: flex;

          .timeLine {
            width: 40%;
            height: 100%;
            overflow: auto;
            padding: 10px 0 10px 16px;
            background: rgba(216, 216, 216, 0.12);
            box-sizing: border-box;
          }
          .auctionDetail {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            margin-left: 26px;
            .actionCode,
            .actionTime,
            .deposit,
            .projectConsultation {
              margin-right: 40px;
              span {
                &:first-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: #00000066;
                  margin-right: 12px;
                }
                &:last-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: #000000d9;
                }
              }
            }
          }
        }

        .btnBox {
          // width: 100%;
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          .pub {
            width: 88px;
            height: 32px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            margin-left: 20px;
          }
          .hall {
            background: #0dac65;
            border: 1px solid #0dac65;
            color: #ffffff;
          }
          .detail {
            background: #ffffff;
            border: 1px solid #0dac65;
            color: #0dac65;
          }
          .cancel {
            background: #ffffff;
            border: 1px solid #e0e0e0;
            color: #666666;
          }
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .noData {
    width: 100%;
    height: 210px;
    line-height: 210px;
    text-align: center;
    color: #00000066;
  }

  .payPriceBox {
    .priceBox {
      padding-bottom: 33px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .title {
        font-weight: 400;
        font-size: 14px;
        color: #000000d9;
        text-align: center;
      }
      .price {
        font-weight: 500;
        font-size: 24px;
        color: #000000d9;
        text-align: center;
        span {
          font-size: 32px;
          margin-left: 8px;
        }
      }
    }
    .amountAccounting {
      margin-top: 41px;
      padding-bottom: 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .title {
        font-weight: 600;
        font-size: 16px;
        color: #000000d9;
      }
      .deposit {
        padding-left: 20px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
      }
      .realPay {
        margin-top: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
      }
    }

    .payPassword {
      margin-top: 32px;
      .payAccount {
        // display: flex;
        // justify-content: space-between;
        div {
          &:first-child {
            font-weight: 600;
            font-size: 16px;
            color: #000000d9;
          }
          &:last-child {
            text-align: right;
            margin-top: 10px;
          }
        }
      }
      .balance {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
    .password {
      margin-top: 20px;
    }
  }
}
</style>
