<template>
  <div class="container">
    <div class="banner">
      <div class="bannerTit">
        <span>我的工作台</span>
      </div>
    </div>

    <div class="containerBox">
      <!-- 个人信息 -->
      <div class="personInfoBox">
        <div class="headSculpture">
          <img
            v-if="userInfo.sex == '0'"
            src="@/assets/images/girl.png"
            alt=""
          />
          <img v-else src="@/assets/images/boy.png" alt="" />
        </div>
        <div class="personFactoryInfo">
          <div class="personRoleInfo personPub">
            <img src="../../assets/images/workSpace/nickName.png" alt="" />
            <span class="personRole">
              {{ personInfo.nickName }}
            </span>
          </div>

          <div class="personName personPub">
            <img
              v-if="userInfo.authenticationStatus == '2'"
              src="../../assets/images/workSpace/personRZ.png"
              alt=""
            />
            <img
              v-else
              src="../../assets/images/workSpace/personWRZ.png"
              alt=""
            />
            <span
              v-if="userInfo.authenticationStatus == '2'"
              class="realName"
              >{{ personInfo.realName }}</span
            >
            <span
              v-else-if="userInfo.authenticationStatus == '0'"
              class="realName"
              >用户尚未实名</span
            >
          </div>

          <div class="factoryInfo personPub">
            <img
              v-if="userInfo.manuId"
              src="../../assets/images/workSpace/facRZ.png"
              alt=""
            />
            <img v-else src="../../assets/images/workSpace/facWRZ.png" alt="" />
            <span v-if="userInfo.manuId" class="facName">{{
              personInfo.name
            }}</span>
            <span v-else class="facName">用户尚未加入企业</span>
          </div>
        </div>
        <div class="personDatas">
          <div class="comingAuction">
            <div>{{ this.bidCount.waitStart }}</div>
            <div class="pub">即将竞拍</div>
          </div>

          <div class="history">
            <div>
              <span>{{ this.bidCount.bidWon }}</span
              >/<span>{{ this.bidCount.history }}</span>
            </div>
            <div class="pub">竞拍成功/历史</div>
          </div>

          <div class="comingAuction">
            <div>{{ this.auctionCount.waitStart }}</div>
            <div class="pub">即将拍卖</div>
          </div>

          <div class="history">
            <div>
              <span>{{ this.auctionCount.auctionWon }}</span
              >/<span>{{ this.auctionCount.history }}</span>
            </div>
            <div class="pub">拍卖成功/历史</div>
          </div>

          <div class="pendingProcessing">
            <div>{{ this.bidCount.pending }}</div>
            <div class="pub">待处理事项</div>
          </div>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="btnClass">
        <el-button type="success" plain @click="toAuctionHall()"
          >进入竞拍大厅</el-button
        >
        <el-button type="success" @click="toBidApplication()">
          发起拍卖申请
        </el-button>
      </div>

      <!-- tab页 -->
      <div class="contentBox">
        <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
          <el-tab-pane label="竞拍列表" name="first">
            <auctionList ref="auctionList"></auctionList>
          </el-tab-pane>
          <el-tab-pane label="竞拍成功得标" name="second">
            <successfulBidList ref="successfulBidList"></successfulBidList>
          </el-tab-pane>
          <el-tab-pane label="拍卖列表" name="third">
            <auctionInventory ref="auctionInventory"></auctionInventory>
          </el-tab-pane>
          <el-tab-pane label="拍卖申请" name="fourth">
            <applicationList ref="applicationList"></applicationList>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import auctionList from "./components/auctionList.vue";
import successfulBidList from "./components/successfulBidList.vue";
import auctionInventory from "./components/auctionInventory.vue";
import applicationList from "./components/applicationList.vue";
import {
  getUserInfo,
  bidCount,
  auctionCount,
  enterBidHall,
} from "@/api/workSpace";
import { queryManuFactoryInfo } from "@/api/personnelManagement";

import { mapState } from "vuex";
export default {
  components: {
    auctionList,
    successfulBidList,
    auctionInventory,
    applicationList,
  },
  data() {
    return {
      activeName: "first",
      personInfo: {},
      bidCount: {}, //竞拍数字加待处理
      auctionCount: {}, //拍卖成功
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.getPersonInfo();
    this.getBidCount();
    this.getAuctionCount();
    this.activeName = this.$route.query.activeName || "first";
    this.handleClick(this.activeName);
  },
  methods: {
    handleClick(name) {
      // 在这里根据切换的标签页调用各自组件的方法
      switch (name) {
        case "first":
          this.$refs.auctionList.getAuctionList();
          break;
        case "second":
          this.$refs.successfulBidList.getList();
          break;
        case "third":
          this.$refs.auctionInventory.getList();
          break;
        case "fourth":
          this.$refs.applicationList.getList();
          break;
        default:
          break;
      }
    },
    // 获取个人信息
    getPersonInfo() {
      getUserInfo().then((res) => {
        this.personInfo = res.data;
      });
    },

    // 获取竞拍数字加待处理事项
    getBidCount() {
      bidCount().then((res) => {
        this.bidCount = { ...res.data };
      });
    },
    // 获取拍卖数字
    getAuctionCount() {
      auctionCount().then((res) => {
        this.auctionCount = { ...res.data };
      });
    },

    // 发起拍卖申请
    toBidApplication() {
      if (!this.userInfo.manuId) {
        this.$message({
          type: "warning",
          message: "请先加入企业再操作！",
        });
      } else {
        queryManuFactoryInfo().then((res) => {
          if (
            res.data.signatureStatus == "0" ||
            res.data.signatureStatus == "4"
          ) {
            this.$router.push({
              path: "/initiateBidApplication",
            });
          } else {
            this.$message({
              type: "warning",
              message: "企业尚未实名认证，请完成企业实名后再操作。",
            });
          }
        });
      }
    },

    // 进入竞拍大厅
    toAuctionHall() {
      if (!this.userInfo.manuId) {
        this.$message({
          type: "warning",
          message: "请先加入企业再操作！",
        });
      } else {
        queryManuFactoryInfo().then((res) => {
          // 判断企业签章是否有效先注释，和报名那块同步，产品说在签合同之前校验下企业签章是否有效即可
          // if (res.data.signatureStatus == "0") {
          enterBidHall().then((res) => {
            if (res.code == 200) {
              this.$router.push({
                path: "/bidHall",
                query: {
                  params: JSON.stringify({
                    auctionCode: res.data.auctionCode,
                    application: res.data.application,
                    startingPrice: res.data.startingPrice,
                  }),
                },
              });
            } else {
              this.$message({
                type: "warning",
                message: res.msg,
              });
            }
          });
          // } else {
          //   this.$message({
          //     type: "warning",
          //     message: "您当前企业签章已过期，无法进行拍卖！",
          //   });
          // }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__item {
  font-size: 16px;
}

.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
.container {
  .banner {
    width: 100%;
    height: 200px;
    background: url("../../assets/images/workSpace/banner.png") center/cover
      no-repeat;

    .bannerTit {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      position: relative;
      span {
        position: absolute;
        bottom: 36px;
      }
    }
  }

  .containerBox {
    width: 1200px;
    // height: 100px;
    // background: #ccc;
    margin: 0 auto;
    padding-top: 40px;
    .personInfoBox {
      width: 100%;
      height: 188px;
      // background: pink;
      display: flex;
      align-items: center;
      padding-left: 20px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      box-sizing: border-box;
      .headSculpture {
        width: 100px;
        height: 100px;
        // background: url("../../assets/images/headImg.jpeg") center/cover
        // no-repeat;
        // border-radius: 50%;
        margin-right: 24px;
      }

      .personFactoryInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 85px;
        .personPub {
          height: 33.3%;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
        .personRoleInfo {
          .personRole {
            font-weight: 600;
            font-size: 16px;
            color: #000000d9;
          }
        }
        .personName {
          .realName {
            font-weight: 400;
            font-size: 16px;
            color: #000000d9;
          }
        }
        .factoryInfo {
          .facName {
            font-weight: 400;
            font-size: 16px;
            color: #000000d9;
          }
        }
      }

      .personDatas {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .pub {
          font-weight: 400;
          font-size: 14px;
          color: #00000099;
        }
        .comingAuction {
          text-align: center;
          div {
            &:first-child {
              font-weight: 500;
              font-size: 32px;
              color: #0dac65;
            }
          }
        }
        .history {
          text-align: center;
          div {
            &:first-child {
              font-weight: 500;
              font-size: 20px;
              color: #00000066;
              span {
                &:first-child {
                  font-weight: 500 !important;
                  font-size: 32px !important;
                  color: #00000099 !important;
                }
              }
            }
          }
        }
        .pendingProcessing {
          text-align: center;
          div {
            &:first-child {
              font-weight: 500;
              font-size: 32px;
              color: #ff7f00;
            }
          }
        }
      }
    }

    .btnClass {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      .auctionHall {
        width: 140px;
        height: 40px;
        border: 1px solid #0dac65;
        font-weight: 400;
        font-size: 18px;
        color: #0dac65;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        box-sizing: border-box;
      }
      .auctionApplication {
        width: 140px;
        height: 40px;
        background: #0dac65;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        margin-left: 20px;
      }
    }

    .contentBox {
      margin-top: 40px;
    }
  }
}
</style>
