var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actionBox"},[(_vm.listData.length != 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"listBox"},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.url,"alt":""}})]),_c('div',{staticClass:"listContent"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"public",class:{
              status1:
                item.status == 0 ||
                item.status == 2 ||
                item.status == 3 ||
                item.status == 4,
              status2: item.status == 1 || item.status == 5,
              status3: item.status == 6 || item.status == 7,
            }},[_vm._v(" "+_vm._s(item.status == "0" ? "待处理" : item.status == "1" ? "处理中" : item.status == "2" ? "待确认" : item.status == "3" ? "待支付" : item.status == "4" ? "待发布" : item.status == "5" ? "已发布" : item.status == "6" ? "已驳回" : item.status == "7" ? "已撤回" : "---")+" ")]),_c('div',{staticClass:"wz"},[_vm._v(" "+_vm._s(item.applicationTitle)+" ")])]),_c('div',{staticClass:"contentDetail"}),_c('div',{staticClass:"auctionDetail"},[_c('div',{staticClass:"actionCode"},[_c('span',[_vm._v("申请编号")]),_c('span',[_vm._v(_vm._s(item.applicationNumber || "---"))])]),_c('div',{staticClass:"actionTime"},[_c('span',[_vm._v("申请时间")]),_c('span',[_vm._v(_vm._s(item.applicationTime))])]),_c('div',{staticClass:"projectConsultation"},[_c('span',[_vm._v("策拍人")]),_c('span',[_vm._v(_vm._s(item.status == "0" || item.status == "7" ? "---" : item.person))])])]),_c('div',{staticClass:"btnBox"},[_c('div',{staticClass:"tips"},[_c('span',{staticClass:"imgStyle"},[(item.status == 6)?_c('img',{attrs:{"src":require("@/assets/images/workSpace/tip.png"),"alt":""}}):_vm._e()]),(item.status == 6)?_c('span',{class:{
                fail: item.status == 6,
              }},[_vm._v(_vm._s(item.reason))]):_vm._e()]),_c('div',{staticClass:"btn"},[(item.status == '0' || item.status == '1')?_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.updateInfo(item.applicationNumber, item.status)}}},[_vm._v(" 修改信息 ")]):_vm._e(),(
                item.status == '0' ||
                  item.status == '1' ||
                  item.status == '2' ||
                  item.status == '3'
              )?_c('el-button',{attrs:{"size":"small","type":"success","plain":""},on:{"click":function($event){return _vm.onWithdraw(item.applicationNumber)}}},[_vm._v(" 撤回申请 ")]):_vm._e(),(item.status == '2')?_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.viewPlan(item.applicationNumber, item.status)}}},[_vm._v(" 查看方案 ")]):_vm._e(),(item.status == '3')?_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.toPay(item.applicationNumber)}}},[_vm._v(" 立即支付 ")]):_vm._e(),(
                item.status == '5' || item.status == '6' || item.status == '7'
              )?_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.handleDelete(item.applicationNumber)}}},[_vm._v(" 删除信息 ")]):_vm._e()],1)])])])}),0):_c('div',{staticClass:"noData"},[_vm._v("暂无数据")])])}
var staticRenderFns = []

export { render, staticRenderFns }