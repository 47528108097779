<!-- 拍卖申请列表 -->
<template>
  <div class="actionBox">
    <div class="listBox" v-loading="loading" v-if="listData.length != 0">
      <div class="list" v-for="(item, index) in listData" :key="index">
        <div class="img">
          <img :src="item.url" alt="" />
        </div>

        <div class="listContent">
          <div class="title">
            <div
              :class="{
                status1:
                  item.status == 0 ||
                  item.status == 2 ||
                  item.status == 3 ||
                  item.status == 4,
                status2: item.status == 1 || item.status == 5,
                status3: item.status == 6 || item.status == 7,
              }"
              class="public"
            >
              {{
                item.status == "0"
                  ? "待处理"
                  : item.status == "1"
                  ? "处理中"
                  : item.status == "2"
                  ? "待确认"
                  : item.status == "3"
                  ? "待支付"
                  : item.status == "4"
                  ? "待发布"
                  : item.status == "5"
                  ? "已发布"
                  : item.status == "6"
                  ? "已驳回"
                  : item.status == "7"
                  ? "已撤回"
                  : "---"
              }}
            </div>
            <div class="wz">
              {{ item.applicationTitle }}
            </div>
          </div>
          <div class="contentDetail">
            <!-- <span>B品电池</span>
            <span>30吨</span>
            <span>磷酸铁锂 </span>
            <span>宁德时代</span>
            <span>新能源汽车</span>
            <span>整包带外壳</span> -->
          </div>

          <div class="auctionDetail">
            <div class="actionCode">
              <span>申请编号</span>
              <span>{{ item.applicationNumber || "---" }}</span>
            </div>
            <div class="actionTime">
              <span>申请时间</span>
              <span>{{ item.applicationTime }}</span>
            </div>
            <!-- <div class="deposit">
              <span>保证金</span>
              <span>￥{{ formatMoneyString(item.deposit) }}</span>
            </div> -->
            <div class="projectConsultation">
              <span>策拍人</span>
              <span>{{
                item.status == "0" || item.status == "7" ? "---" : item.person
              }}</span>
            </div>
          </div>

          <div class="btnBox">
            <div class="tips">
              <span class="imgStyle">
                <img
                  v-if="item.status == 6"
                  src="@/assets/images/workSpace/tip.png"
                  alt=""
                />
                <!-- <img
                  v-if="item.status == 1"
                  src="@/assets/images/workSpace/tip1.png"
                  alt=""
                /> -->
              </span>
              <span
                :class="{
                  fail: item.status == 6,
                }"
                v-if="item.status == 6"
                >{{ item.reason }}</span
              >
              <!-- msg: item.status == 1, -->
            </div>
            <div class="btn">
              <el-button
                size="small"
                type="success"
                v-if="item.status == '0' || item.status == '1'"
                @click="updateInfo(item.applicationNumber, item.status)"
              >
                修改信息
              </el-button>
              <el-button
                size="small"
                type="success"
                plain
                v-if="
                  item.status == '0' ||
                    item.status == '1' ||
                    item.status == '2' ||
                    item.status == '3'
                "
                @click="onWithdraw(item.applicationNumber)"
              >
                撤回申请
              </el-button>
              <el-button
                size="small"
                type="success"
                v-if="item.status == '2'"
                @click="viewPlan(item.applicationNumber, item.status)"
              >
                查看方案
              </el-button>
              <el-button
                size="small"
                type="success"
                v-if="item.status == '3'"
                @click="toPay(item.applicationNumber)"
              >
                立即支付
              </el-button>
              <el-button
                size="small"
                v-if="
                  item.status == '5' || item.status == '6' || item.status == '7'
                "
                @click="handleDelete(item.applicationNumber)"
              >
                删除信息
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="noData">暂无数据</div>
  </div>
</template>

<script>
import {
  getApplicationList,
  withdrawUpdateStatus,
  auctionDelete,
} from "@/api/workSpace";
export default {
  data() {
    return {
      baseUrl: "/api",
      loading: false,
      status: 6,
      listData: [],
    };
  },
  mounted() {
    // this.getList();
  },
  methods: {
    // 撤回申请
    onWithdraw(data) {
      this.$confirm(
        "您确定要撤回申请吗？撤回申请后信息不可恢复，请谨慎操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let params = {
          status: 7,
          applicationNumber: data,
        };
        withdrawUpdateStatus(params).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
    // 修改信息跳转到拍卖申请步骤3
    updateInfo(data, status) {
      this.$router.push({
        path: "/initiateBidApplication",
        query: {
          auctionCode: data,
          active: 2,
          status: status,
        },
      });
    },

    // 查看方案跳转到拍卖申请步骤4
    viewPlan(data, status) {
      this.$router.push({
        path: "/initiateBidApplication",
        query: {
          auctionCode: data,
          active: 3,
          status: status,
        },
      });
    },

    // 立即支付
    toPay(data) {
      this.$router.push({
        path: "/initiateBidApplication",
        query: {
          auctionCode: data,
          active: 4,
        },
      });
    },

    getList() {
      this.loading = true;
      getApplicationList().then((res) => {
        this.listData = res.data.map((item) => {
          item.url =
            this.baseUrl +
            "/file/displayResource?objectName=" +
            item.pictureName[0];
          if (item.operator && item.operatorNumber) {
            item.person = item.operator + " " + item.operatorNumber;
          } else {
            item.person = "---";
          }

          item.dateDayStartTime = item.dateDay + " " + item.auctionStartTime;
          return item;
        });
        this.loading = false;
      });
    },

    // 删除信息
    handleDelete(data) {
      this.$confirm(
        "您确定要删除吗？删除后后信息不可恢复，请谨慎操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        auctionDelete(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
.el-button--default {
  background-color: #fff;
}
.actionBox {
  width: 100%;
  padding-top: 25px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .listBox {
    width: 100%;
    box-sizing: border-box;

    .list {
      width: 100%;
      height: 210px;
      padding: 20px;
      // background-color: #ccc;
      box-sizing: border-box;
      display: flex;
      // box-shadow: 0 1px 0 0 #e0e0e0;
      .img {
        width: 305px;
        height: 170px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .listContent {
        width: calc(100% - 305px);
        padding-left: 28px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        .title {
          width: 100%;
          display: flex;
          .public {
            width: 84px;
            height: 24px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
          }
          // 待处理，待确认，待支付，待发布显示文字
          .status1 {
            background: rgba(255, 127, 0, 0.1);
            color: #ff7f00;
          }
          .status2 {
            background: rgba(13, 172, 101, 0.1);
            color: #0dac65;
          }
          .status3 {
            background: #f0f0f0;
            color: #00000099;
          }
          .wz {
            width: calc(100% - 84px);
            font-weight: 500;
            font-size: 18px;
            color: #000000d9;
            overflow: hidden;
            white-space: nowrap; /* 防止换行 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .contentDetail {
          width: 100%;
          height: 20px;
          margin-top: 18.5px;
          span {
            padding: 0 10px;
            border-right: 1px solid rgba(0, 0, 0, 0.6);
            font-weight: 400;
            font-size: 14px;
            color: #00000099;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              border: none;
            }
          }
        }

        .auctionDetail {
          width: 100%;
          display: flex;
          margin-top: 18.5px;
          .actionCode,
          .actionTime,
          .deposit,
          .projectConsultation {
            margin-right: 20px;
            span {
              &:first-child {
                font-weight: 400;
                font-size: 14px;
                color: #00000066;
                margin-right: 12px;
              }
              &:last-child {
                font-weight: 400;
                font-size: 14px;
                color: #000000d9;
              }
            }
          }
          // .actionTime{

          // }
          // .deposit{

          // }
          // .projectConsultation{

          // }
        }

        .btnBox {
          // width: 100%;
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 0;
          // position: absolute;
          // bottom: 0;
          // left: 20px;
          .btn {
            display: flex;
            .pub {
              width: 88px;
              height: 32px;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              line-height: 32px;
              cursor: pointer;
              margin-left: 20px;
            }
            .modify {
              background: #0dac65;
              border: 1px solid #0dac65;
              color: #ffffff;
            }
            .withdraw {
              background: #ffffff;
              border: 1px solid #0dac65;
              color: #0dac65;
            }
            .delete {
              background: #ffffff;
              border: 1px solid #e0e0e0;
              color: #ff4421;
            }
          }
          .tips {
            font-weight: 400;
            font-size: 12px;
            display: flex;
            .imgStyle {
              width: 16px;
              height: 16px;
              img {
                width: 100%;
              }
            }
            .fail {
              height: 16px;
              line-height: 16px;
              color: #ff4421;
            }
            .msg {
              height: 16px;
              line-height: 16px;
              color: #999999;
            }
          }
        }
      }
    }
  }

  .noData {
    width: 100%;
    height: 210px;
    line-height: 210px;
    text-align: center;
    color: #00000066;
  }
}
</style>
