<!-- 竞拍列表 -->
<template>
  <div class="actionBox">
    <el-form
      :model="queryParams"
      ref="queryRef"
      :inline="true"
      label-width="130px"
      size="medium"
    >
      <div class="formContainer">
        <div class="pubFormStyle" style="text-align:left">
          <el-form-item label-width="70px" label="拍卖编号" prop="auctionCode">
            <el-input
              v-model.trim="queryParams.auctionCode"
              placeholder="请输入拍卖编号"
              clearable
              @keyup.enter="handleQuery"
            />
          </el-form-item>
        </div>
        <!-- <div class="pubFormStyle">
          <el-form-item label="标的类型">
            <el-select
              v-model="queryParams.status"
              placeholder="请选择标的类型"
              clearable
            >
              <el-option
                v-for="dict in acution_status"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </div> -->

        <div class="pubFormStyle">
          <el-form-item label="开拍日期" prop="dateDay">
            <el-date-picker
              v-model="queryParams.dateDay"
              type="date"
              placeholder="请选择开拍日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>

        <div class="pubFormStyle">
          <el-form-item label="标的地址" prop="address">
            <el-cascader v-model="queryParams.address" :options="areaList"  style="width:100%;"/>
          </el-form-item>
        </div>

        <div class="pubFormStyle">
          <el-form-item label-width="70px" label="排序方式" prop="orderBy">
            <el-select
              v-model="queryParams.orderBy"
              placeholder="请选择排序方式"
              clearable
            >
              <el-option label="按开始时间倒序" value="startTime" />
              <el-option label="按报名时间倒序" value="applicationTime" />
            </el-select>
          </el-form-item>
        </div>
        <div class="pubFormStyle searchStyle">
          <el-button size="small" type="success" plain @click="handleQuery">筛选</el-button>
          <el-button size="small" type="success" plain @click="resetQuery">重置</el-button>
        </div>
      </div>
    </el-form>

    <div class="listBox" v-loading="loading" v-if="auctionList.length != 0">
      <div class="list" v-for="(item, index) in auctionList" :key="index">
        <div class="img">
          <img :src="item.url" alt="" />
        </div>

        <div class="listContent">
          <div class="title">
            <div
              :class="{
                status1: item.auctionStatus == '0',
                status2: item.auctionStatus == '1',
                status3: item.auctionStatus != '0' && item.auctionStatus != '1',
              }"
              class="public"
            >
              {{
                item.auctionStatus == "0"
                  ? "即将开始"
                  : item.auctionStatus == "1"
                  ? "正在拍卖"
                  : "已结束"
              }}
            </div>
            <div class="wz">
              {{ item.auctionTitle }}
            </div>
          </div>
          <div class="contentDetail">
            <!-- <span>B品电池</span>
            <span>30吨</span>
            <span>磷酸铁锂 </span>
            <span>宁德时代</span>
            <span>新能源汽车</span>
            <span>整包带外壳</span> -->
          </div>

          <div class="auctionDetail">
            <div class="actionCode">
              <span>拍卖编号</span>
              <span>{{ item.auctionCode }}</span>
            </div>
            <div class="actionTime">
              <span>拍卖时间</span>
              <span>{{ item.dateDayStartTime }}</span>
            </div>
            <div class="deposit">
              <span>保证金</span>
              <span>￥{{ formatMoneyString(item.deposit) }}</span>
            </div>
            <div class="projectConsultation">
              <span>项目咨询</span>
              <span>{{ item.person }}</span>
            </div>
          </div>

          <div class="btnBox">
            <!-- <div
              class="hall pub"
              v-if="item.auctionStatus == '0' || item.auctionStatus == '1'"
              @click="toBidHall(item.actionCode,item.startingPrice)"
            >
              进入大厅
            </div> -->
            <el-button
            size="small"
              type="success"
              plain
              @click="toBidDetail(item.auctionCode)"
            >
              拍卖详情
            </el-button>
            <el-button
              size="small"
              v-if="item.auctionStatus == '0'"
              @click="hangleCancel(item.auctionCode)"
            >
              取消报名
            </el-button>
          </div>
        </div>
      </div>

      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="queryParams.pageNum"
          :page-size.sync="queryParams.pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div v-else class="noData">暂无数据</div>
  </div>
</template>

<script>
import { getAuctionList, cancelApplication } from "@/api/workSpace";
import { mapState } from "vuex";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      status: 0,
      auctionList: [], //列表数据
      baseUrl: "/api",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      areaList: (state) => state.area.areaList,
    }),
  },
  mounted() {
    // this.getAuctionList();
  },
  methods: {
    // 查询
    handleQuery() {
      this.getAuctionList();
    },
    // 重置
    resetQuery() {
      this.$refs["queryRef"].resetFields();
      this.getAuctionList();
    },
    // 分页
    handleCurrentChange() {
      this.getAuctionList();
    },

    getAuctionList() {
      this.loading = true;
      let { address, ...args } = this.queryParams;
      if (this.queryParams.address) {
        let country = JSON.parse(JSON.stringify(this.queryParams.address));
        country = country.join("-");
        args.country = country;
      }
      getAuctionList(args).then((res) => {
        this.auctionList = res.rows.map((item) => {
          item.url =
            this.baseUrl +
            "/file/displayResource?objectName=" +
            item.objectNames[0];
          item.dateDayStartTime = item.dateDay + " " + item.auctionStartTime;
          item.person = item.operator + " " + item.operatorNumber;
          return item;
        });

        this.total = res.total;
        this.loading = false;
      });
    },

    // 取消报名
    hangleCancel(data) {
      this.$confirm(
        "您确定要取消竞拍报名吗？取消报名后，已冻结的保证金将解冻并存入企业钱包当中。",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        cancelApplication({ auctionCode: data }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getAuctionList();
          }
        });
      });
    },

    // 拍卖详情
    toBidDetail(data) {
      this.$router.push({
        path: "/biddingProject/details",
        query: {
          auctionCode: data,
        },
      });
    },

    // 进入竞拍大厅
    toBidHall(auctionCode, startingPrice) {
      this.$router.push({
        path: "/bidHall",
        query: {
          auctionCode: auctionCode,
          application: 2,
          startingPrice: startingPrice,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--medium .el-input__inner{
  height: 32px;
  line-height: 32px;
}
/deep/.el-form-item__label{
  color: rgba(0, 0, 0, 0.85);
}
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
.el-button--default {
  background-color: #fff;
}
.actionBox {
  width: 100%;
  padding-top: 25px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .listBox {
    width: 100%;
    box-sizing: border-box;

    .list {
      width: 100%;
      height: 210px;
      padding: 20px;
      // background-color: #ccc;
      box-sizing: border-box;
      display: flex;
      box-shadow: 0 1px 0 0 #e0e0e0;
      .img {
        width: 305px;
        height: 170px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .listContent {
        width: calc(100% - 305px);
        padding-left: 28px;
        box-sizing: border-box;
        position: relative;
        .title {
          width: 100%;
          display: flex;
          .public {
            width: 84px;
            height: 24px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
          }
          // 即将开始
          .status1 {
            background: rgba(13, 172, 101, 0.1);
            color: #0dac65;
          }
          // 正在拍卖
          .status2 {
            background: rgba(255, 127, 0, 0.1);
            color: #ff7f00;
          }
          // 已结束
          .status3 {
            background: #f0f0f0;
            color: #00000099;
          }
          .wz {
            width: calc(100% - 84px);
            font-weight: 500;
            font-size: 18px;
            color: #000000d9;
            overflow: hidden;
            white-space: nowrap; /* 防止换行 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .contentDetail {
          width: 100%;
          height: 20px;
          margin-top: 18.5px;
          span {
            padding: 0 10px;
            border-right: 1px solid rgba(0, 0, 0, 0.6);
            font-weight: 400;
            font-size: 14px;
            color: #00000099;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              border: none;
            }
          }
        }

        .auctionDetail {
          width: 100%;
          display: flex;
          margin-top: 18.5px;
          .actionCode,
          .actionTime,
          .deposit,
          .projectConsultation {
            margin-right: 20px;
            span {
              &:first-child {
                font-weight: 400;
                font-size: 14px;
                color: #00000066;
                margin-right: 12px;
              }
              &:last-child {
                font-weight: 400;
                font-size: 14px;
                color: #000000d9;
              }
            }
          }
          // .actionTime{

          // }
          // .deposit{

          // }
          // .projectConsultation{

          // }
        }

        .btnBox {
          // width: 100%;
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          .pub {
            width: 88px;
            height: 32px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            margin-left: 20px;
          }
          .hall {
            background: #0dac65;
            border: 1px solid #0dac65;
            color: #ffffff;
          }
          .detail {
            background: #ffffff;
            border: 1px solid #0dac65;
            color: #0dac65;
          }
          .cancel {
            background: #ffffff;
            border: 1px solid #e0e0e0;
            color: #666666;
          }
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .noData {
    width: 100%;
    height: 210px;
    line-height: 210px;
    text-align: center;
    color: #00000066;
  }
}
</style>
