<!-- 竞拍成功得标 -->
<template>
  <div class="successfulBidBox">
    <div class="listBox" v-loading="loading" v-if="successList.length != 0">
      <div class="list" v-for="(item, index) in successList" :key="index">
        <div class="img">
          <img :src="item.url" alt="" />
        </div>

        <div class="listContent">
          <div class="title">
            <div
              class="status"
              :class="{
                status1: item.bidderStatus == '2',
                status2: item.bidderStatus == '3',
              }"
            >
              {{
                item.bidderStatus == null || item.bidderStatus == "0"
                  ? "待支付"
                  : item.bidderStatus == "1"
                  ? "待签署"
                  : item.bidderStatus == "2"
                  ? "签署成功"
                  : "签署失败"
              }}
            </div>
            <div class="wz">
              {{ item.auctionTitle }}
            </div>
          </div>
          <div class="contentDetail">
            <span>最后完成时间：</span>
            <span>{{ item.contractLastTime }}</span>
          </div>

          <div class="auctionDetail">
            <div class="actionCode">
              <span>乙方企业名称</span>
              <span>{{ item.applyForBusiness || "---" }}</span>
            </div>
            <div class="actionTime">
              <span>合同签署日期</span>
              <span>{{ item.contractTime || "---" }}</span>
            </div>
          </div>

          <div class="btnBox">
            <el-button
              size="small"
              type="success"
              plain
              v-if="item.bidderStatus == '2'"
              @click="viewContract(item.signFlowId)"
              >查看合同</el-button
            >
            <el-button
              size="small"
              type="success"
              plain
              v-if="item.bidderStatus == '2'"
              @click="downloadContract(item.signFlowId)"
              >下载合同</el-button
            >
            <el-button
              size="small"
              type="success"
              v-if="
                item.auctionStatus == '2' &&
                  (item.bidderStatus == null || item.bidderStatus == '0')
              "
              @click="toPay(item.auctionServiceFee, item.auctionCode)"
            >
              支付费用
            </el-button>
            <!-- <el-button
              type="success"
              v-if="item.bidderStatus == '1'"
              @click="
                $router.push({
                  path: '/contract',
                })
              "
            >
              签署合同
            </el-button> -->
          </div>
        </div>
      </div>
    </div>

    <div v-else class="noData">暂无数据</div>

    <!-- 支付弹窗 -->
    <el-dialog
      title="企业钱包"
      :visible.sync="bmPayVisible"
      width="480px"
      :close-on-click-modal="false"
    >
      <div class="payPriceBox">
        <div class="priceBox">
          <div class="title">支付金额</div>
          <div class="price">
            ￥<span>{{
              formatMoneyString((auctionData.auctionServiceFee / 100).toFixed(2))
            }}</span>
          </div>
        </div>
 
        <div class="amountAccounting">
          <div class="title">金额核算</div>

          <div class="deposit">
            <div>竞拍综合服务费</div>
            <div>
              ￥{{
                formatMoneyString((auctionData.auctionServiceFee / 100).toFixed(2))
              }}
            </div>
          </div>

          <div class="realPay">
            <div>实际支付</div>
            <div>
              ￥{{
                formatMoneyString((auctionData.auctionServiceFee / 100).toFixed(2))
              }}
            </div>
          </div>
        </div>

        <div class="payPassword">
          <div class="payAccount">
            <div>支付密码</div>
            <div>支付账户：{{ userInfo.manuName }}-企业钱包</div>
          </div>
          <div class="balance">
            可用余额：￥{{ formatMoneyString(withdrawalForm.availableBalance) }}
          </div>
        </div>

        <div class="password">
          <PasswordInput
            ref="passwordInputRef"
            v-model="withdrawalForm.paymentPassword" 
            has-gap
            @complete="onPasswordInputComplete"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSuccessList, bidPay, viewContract } from "@/api/workSpace";
import { balance } from "@/api/account";
import { getInfo } from "@/api/index";
import { mapState } from "vuex";
import PasswordInput from "@/pages/AccountSettings/components/PasswordInput.vue";
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
export default {
  components: {
    PasswordInput,
  },
  data() {
    return {
      bmPayVisible: false,
      baseUrl: "/api",
      successList: [],
      loading: false,
      withdrawalForm: {},
      auctionData: {},
    };
  },
  watch: {
    bmPayVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs["passwordInputRef"].focus();
        });
      }
    },
  },
  mounted() {
    // this.getList();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    // 查看合同
    viewContract(data) {
      this.$router.push({
        path: "/contract",
        query: {
          signFlowId: data,
        },
      });
    },
    // 下载
    downloadContract(data) {
      viewContract({ signFlowId: data }).then((res) => {
        this.downloadFile(res.data.downloadUrl);
      });
    },

    // 下载文件不公用下载文件方法，需要前端解析后端返回的路径的来得到文件名称
    getFileNameFromUrl(url) {
      const matches = url.match(/\/([^\/?#]+)[^\/]*$/);
      return matches ? decodeURIComponent(matches[1]) : null;
    },

    // 下载文件
    downloadFile(fileURL) {
      // 获取文件名
      const fileName = this.getFileNameFromUrl(fileURL);
      // 使用fetch下载文件
      fetch(fileURL)
        .then((response) => response.blob())
        .then((blob) => {
          // 创建一个临时链接并触发下载
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName || "downloaded_file"; // 使用从URL解析得到的文件名，如果没有则使用默认文件名
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("文件下载失败：", error);
        });
    },
    getList() {
      this.loading = true;
      getSuccessList().then((res) => {
        // if (res.code == 200) {
        this.successList = res.data.list.map((item) => {
          item.url =
            this.baseUrl +
            "/file/displayResource?objectName=" +
            item.objectNames[0];
          return item;
        });
        this.loading = false;
        // }
      });
    },

    // 支付费用
    toPay(auctionServiceFee, auctionCode) {
      getInfo().then((res) => {
        if (res.code == 200) {
          if (res.user.walletStatus == "2") {
            this.$message({
              type: "warning",
              message: "您的账户已冻结，请联系管理员操作！",
            });
          } else {
            this.auctionData.auctionServiceFee = auctionServiceFee;
            this.auctionData.auctionCode = auctionCode;
            this.bmPayVisible = true;
            balance().then(({ code, data }) => {
              if (code == 200) this.withdrawalForm = data;
            });
          }
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },

    // 密码输入完成回调
    onPasswordInputComplete(val) {
      let pwd = encrypt(val);
      let param = {
        authCode: this.auctionData.auctionCode,
        amount: (Number(this.auctionData.auctionServiceFee) / 100).toFixed(2),
        pwd,
      };
      bidPay(param).then((res) => {
        if (res.code == 200) {
          this.bmPayVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: res.msg,
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
.successfulBidBox {
  width: 100%;
  padding-top: 25px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .listBox {
    width: 100%;
    box-sizing: border-box;

    .list {
      width: 100%;
      height: 210px;
      padding: 20px;
      // background-color: #ccc;
      box-sizing: border-box;
      display: flex;
      // box-shadow: 0 1px 0 0 #e0e0e0;
      .img {
        width: 305px;
        height: 170px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .listContent {
        width: calc(100% - 305px);
        padding-left: 28px;
        box-sizing: border-box;
        position: relative;
        .title {
          width: 100%;
          display: flex;
          .status {
            width: 84px;
            height: 24px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
            background: rgba(255, 127, 0, 0.1);
            color: #ff7f00;
          }
          .status1 {
            background: rgba(13, 172, 101, 0.1);
            color: #0dac65;
          }
          .status2 {
            background: #f0f0f0;
            color: #00000099;
          }
          .wz {
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            color: #000000d9;
            overflow: hidden;
            white-space: nowrap; /* 防止换行 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .contentDetail {
          width: 100%;
          margin-top: 18.5px;
          span {
            // padding: 0 10px;
            // border-right: 1px solid rgba(0, 0, 0, 0.6);
            font-weight: 400;
            font-size: 14px;
            color: #00000099;
            // &:first-child {
            //   padding-left: 0;
            // }
            // &:last-child {
            //   border: none;
            // }
          }
        }

        .auctionDetail {
          width: 100%;
          display: flex;
          margin-top: 18.5px;
          .actionCode,
          .actionTime {
            margin-right: 40px;
            span {
              &:first-child {
                font-weight: 400;
                font-size: 14px;
                color: #00000066;
                margin-right: 12px;
              }
              &:last-child {
                font-weight: 400;
                font-size: 14px;
                color: #000000d9;
              }
            }
          }
          // .actionTime{

          // }
          // .deposit{

          // }
          // .projectConsultation{

          // }
        }

        .btnBox {
          // width: 100%;
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          .pub {
            width: 88px;
            height: 32px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            margin-left: 20px;
          }
          .hall {
            background: #0dac65;
            border: 1px solid #0dac65;
            color: #ffffff;
          }
          .pay {
            background: #2cb679;
            border: 1px solid #0dac65;
            color: #ffffff;
          }
          .down {
            background: #ffffff;
            border: 1px solid #0dac65;
            color: #0dac65;
          }
        }
      }
    }
  }

  .noData {
    width: 100%;
    height: 210px;
    line-height: 210px;
    text-align: center;
    color: #00000066;
  }

  .payPriceBox {
    .priceBox {
      padding-bottom: 33px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .title {
        font-weight: 400;
        font-size: 14px;
        color: #000000d9;
        text-align: center;
      }
      .price {
        font-weight: 500;
        font-size: 24px;
        color: #000000d9;
        text-align: center;
        span {
          font-size: 32px;
          margin-left: 8px;
        }
      }
    }
    .amountAccounting {
      margin-top: 41px;
      padding-bottom: 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .title {
        font-weight: 600;
        font-size: 16px;
        color: #000000d9;
      }
      .deposit {
        padding-left: 20px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
      }
      .realPay {
        margin-top: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
      }
    }

    .payPassword {
      margin-top: 32px;
      .payAccount {
        div {
          &:first-child {
            font-weight: 600;
            font-size: 16px;
            color: #000000d9;
          }
          &:last-child {
            text-align: right;
            margin-top: 10px;
          }
        }
      }
      .balance {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
    .password {
      margin-top: 20px;
    }
  }
}
</style>
